import { ACTIVE_APPLICANTS_FAIL, ACTIVE_APPLICANTS_REQUEST, ACTIVE_APPLICANTS_SUCCESS, ADMIN_LOGIN_FAIL, ADMIN_LOGIN_REQUEST, ADMIN_LOGIN_SUCCESS, CLEAR_ERRORS, IN_ACTIVE_APPLICANTS_FAIL, IN_ACTIVE_APPLICANTS_REQUEST, IN_ACTIVE_APPLICANTS_SUCCESS, LOAD_ADMIN_FAIL, LOAD_ADMIN_REQUEST, LOAD_ADMIN_SUCCESS } from "../Constants/AdminConstants";

export const adminReducer = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_LOGIN_REQUEST:
        case LOAD_ADMIN_REQUEST:
            return {
                loading: true,
                isAuthenticated: false,
                success: false
            }
        case ADMIN_LOGIN_SUCCESS:
        case LOAD_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload.user,
                success: true
            }
        case ADMIN_LOGIN_FAIL:
        case LOAD_ADMIN_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
                success: false,
                user: null
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                success: false,
                isAuthenticated: false,
            };
        default:
            return state;
    }
} 
export const activeApplicantReducer = (state = {}, action) => {
    switch (action.type) {
        
        case ACTIVE_APPLICANTS_REQUEST:
            return {
                loading: true,
                isAuthenticated: false,
                success: false
            }
        
        case ACTIVE_APPLICANTS_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                activeApplicants: action.payload.activeApplicants,
                success: true
            }
        case  ACTIVE_APPLICANTS_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
                success: false,
                activeApplicants: null
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                success: false,
                isAuthenticated: false,
            };
        default:
            return state;
    }
} 

export const InactiveApplicantReducer = (state = {}, action) => {
    switch (action.type) {
        
        case IN_ACTIVE_APPLICANTS_REQUEST:
            return {
                loading: true,
                isAuthenticated: false,
                success: false
            }
        
        case IN_ACTIVE_APPLICANTS_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                InactiveApplicants: action.payload.InactiveApplicants,
                success: true
            }
        case  IN_ACTIVE_APPLICANTS_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
                success: false,
                InactiveApplicants: null
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                success: false,
                isAuthenticated: false,
            };
        default:
            return state;
    }
} 
