import { applyMiddleware, combineReducers, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import {
  InactiveApplicantReducer,
  activeApplicantReducer,
  adminReducer,
} from './Components/Reducers/AdminReducer';
import {
  appliedJobReducer,
  jobReducer,
} from './Components/Reducers/JobpostReducer';

import {
  categoryReducer,
  jobSkillsReducer,
  jobTypeReducer,
} from './Components/Reducers/SearchJobCategoriesReducer';
import modeReducer from './Components/Reducers/DarkModeReducer';
const reducers = combineReducers({
  userAdmin: adminReducer,
  jobs: jobReducer,
  appliedjobs: appliedJobReducer,
  activeApplicants: activeApplicantReducer,
  InactiveApplicants: InactiveApplicantReducer,
  selectedCategory: categoryReducer,
  selectedJobType: jobTypeReducer,
  selectedJobSkills: jobSkillsReducer,
  mode: modeReducer,
});
let initialState = {};
const middleware = [thunk];
const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
