export  const CLEAR_ERRORS = "CLEAR_ERRORS";
export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";

export const  LOAD_ADMIN_REQUEST = " LOAD_ADMIN_REQUEST";
export const  LOAD_ADMIN_SUCCESS = " LOAD_ADMIN_SUCCESS";
export const  LOAD_ADMIN_FAIL = " LOAD_ADMIN_FAIL";

export const  ACTIVE_APPLICANTS_REQUEST = " ACTIVE_APPLICANTS_REQUEST";
export const  ACTIVE_APPLICANTS_SUCCESS = " ACTIVE_APPLICANTS_SUCCESS";
export const  ACTIVE_APPLICANTS_FAIL = " ACTIVE_APPLICANTS_FAIL";

export const  IN_ACTIVE_APPLICANTS_REQUEST = " IN_ACTIVE_APPLICANTS_REQUEST";
export const  IN_ACTIVE_APPLICANTS_SUCCESS = " IN_ACTIVE_APPLICANTS_SUCCESS";
export const  IN_ACTIVE_APPLICANTS_FAIL = " IN_ACTIVE_APPLICANTS_FAIL";