import {
  SET_SELECTED_CATEGORY,
  SET_SELECTED_JOB_TYPE,
  SET_SELECTED_JOB_SKILLS,
} from "../Constants/JobConstant";

const initialCategoryState = {
  selectedCategory: null,
};

export const categoryReducer = (state = initialCategoryState, action) => {
  switch (action.type) {
    case SET_SELECTED_CATEGORY:
      return { ...state, selectedCategory: action.payload };
    default:
      return state;
  }
};

const initialJobTypeState = {
  selectedJobType: null,
};

export const jobTypeReducer = (state = initialJobTypeState, action) => {
  switch (action.type) {
    case SET_SELECTED_JOB_TYPE:
      return { ...state, selectedJobType: action.payload };
    default:
      return state;
  }
};

const initialJobSkills = {
  selectedJobSkills: null,
};

export const jobSkillsReducer = (state = initialJobSkills, action) => {
  switch (action.type) {
    case SET_SELECTED_JOB_SKILLS:
      return { ...state, selectedJobSkills: action.payload };
    default:
      return state;
  }
};
