import React, { useState, useEffect, useContext, createContext } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    token: "",
    data: "",
    fileData: null, // Add fileData in the states
  });

  const loginUser = (userData) => {
    const { user, token, data } = userData;
    setAuth({ user, token, data, fileData: auth.fileData });
    localStorage.setItem("auth", JSON.stringify({ user, token, data }));
  };

  const fileUpload = (fileData) => {
    setAuth((prevAuth) => ({
      ...prevAuth,
      fileData,
    }));
  };
  const [userImage, setUserImage] = useState(null);

  const updateUserImage = (newImage) => {
    setUserImage(newImage);
  };

  const logoutUser = () => {
    setAuth({ user: null, token: "", fileData: null, data: "" });
    sessionStorage.clear();
    localStorage.clear();
  };
  useEffect(() => {
    // Fetch data from session storage on component mount
    const storedAuth = localStorage.getItem("auth");
    if (storedAuth) {
      const parsedAuth = JSON.parse(storedAuth);
      setAuth(parsedAuth);
    }
  }, []);
  return (
    <AuthContext.Provider
      value={{
        auth,
        loginUser,
        logoutUser,
        fileUpload,
        userImage,
        updateUserImage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };
