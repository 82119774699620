import { CLEAR_ERRORS, GET_APPLIED_JOBS_FAIL, GET_APPLIED_JOBS_REQUEST, GET_APPLIED_JOBS_SUCCESS, JOB_POST_FAIL, JOB_POST_REQUEST, JOB_POST_SUCCESS, LOAD_JOB_REQUEST, LOAD_JOB_SUCCESS } from "../Constants/JobConstant";

export const jobReducer = (state = {}, action) => {
    switch (action.type) {
        case JOB_POST_REQUEST:
        case LOAD_JOB_REQUEST:
            return {
                loading: true,
                success: false
            }
        case JOB_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.user,
                success: true
            }
        case LOAD_JOB_SUCCESS:
            return {
                ...state,
                loading:false,
                jobs:action.payload.jobs
            }
        case JOB_POST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
                user: null
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                success: false,

            };
        default:
            return state;
    }
} 

export const appliedJobReducer = (state = {}, action) => {
    switch (action.type) {
      
        case GET_APPLIED_JOBS_REQUEST:
            return {
                loading: true,
                success: false
            }
        
        case GET_APPLIED_JOBS_SUCCESS:
            return {
                ...state,
                loading:false,
                success:true,
                appliedJobs:action.payload.appliedJobs
            }
        case GET_APPLIED_JOBS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
                appliedJobs:[]
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                success: false,

            };
        default:
            return state;
    }
} 