import React, { useEffect, useState } from 'react'
import { Spinner } from "@material-tailwind/react";
import { useLocation, useNavigate } from 'react-router-dom';

const Loader = ({message, path="login"}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [count, setcount] = useState(3)
    useEffect(() => {
     const interval = setInterval(() => {
        setcount((prevalue)=>--prevalue)
     }, 1000);
    
     if (count===0) {
        navigate("/");
      }
      return ()=>{clearInterval(interval)}
    }, [count,navigate,location,path])
    
    return <div className=" flex items-center justify-center flex-col h-screen">
    <Spinner className="h-16 w-16 text-white" />
    <p>{message}</p>
    <h1>redirecting you in {count} second</h1>
    </div>
}

export default Loader
