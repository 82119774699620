import React, { useState, useEffect } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';

import axiosInstance from './../../ulities/axios';

import Loader from '../Spinner';

export default function AdminPrivate() {
  const navigate = useNavigate();
  const [ok, setok] = useState(false);
  const [message, setmessage] = useState('');

  useEffect(() => {
    const authCheck = async () => {
      try {
        const res = await axiosInstance.get('/api/v1/admin/protect-admin');
        console.log(res);
        if (res.data?.ok === true) {
          setok(true);
        } else {
          setok(false);
        }
      } catch (error) {
        if (error.response.status === 401) {
          setmessage(error.response.data?.message);
          navigate('/admin/login');
        }
      }
    };
    authCheck();
  }, []);
  console.log(message);
  return ok ? <Outlet /> : <Loader message={message} path="" />;
}
